.useContentAsset .video-hero-desktop {
  display: block;
}

.useContentAsset .video-hero-mobile {
  display: none;
}

.useContentAsset video {
  width: 100%;
  height: auto;
}

@media (max-width: 772px) {
  .useContentAsset .video-hero-mobile {
    display: block;
  }
}